import { AwardCriteriaType, hasValue, TenderSummary, Supplier } from '@dims/components';
import { Offer0214 } from '@/models/Offer';
import { awardCriteriaType } from '@/services';
import { TenderData0214 } from '@/models';

// TODO: What filters are appropriate for IT?
export default class FilterConfiguration {
  awardTypeFilter = '';

  properties = [];

  filterTender(tender: TenderSummary) {
    return this.filter(tender.awardCriteriaType);
  }
  filterOffer(offer: Offer0214) {
    return this.filter(offer.tenderData.awardCriteriaType);
  }
  filter(awardType?: AwardCriteriaType) {
    return (
      this.awardTypeFilter === ''
          || (hasValue(awardType)
            && awardCriteriaType.getLabel(awardType) === this.awardTypeFilter));
  }
  filterLot(l: { lotNo:string, title: string }) {
    return (
      this.awardTypeFilter === ''
          || l.title.endsWith(this.awardTypeFilter)
    );
  }
  filterSupplier(_tenderData: TenderData0214, _supplier: Supplier) {
    return true;
  }
}
