import { AwardCriteriaType, Offer, Tender, toCurrencyFormatDK2 } from '@dims/components';
import { Offer0214, SolutionType, Tender0214 } from '@/models';
import { awardCriteriaType } from '@/services';

function awardTypeLabel(type?: AwardCriteriaType | null): string {
  return type ? awardCriteriaType.getLabel(type) : '';
}

function getValueString(type: SolutionType | undefined, value: number | undefined): string {
  if (!value) return '';
  if (type === 'Category1') return `${value.toLocaleString()} timer`;
  if (type === 'Category2') return toCurrencyFormatDK2(value);
  return '';
}

export function solutionTypeLabel(type: SolutionType | undefined): string {
  switch (type) {
    case 'Category1':
      return 'Kategori 1 - Kompetencekøb';
    case 'Category2':
      return 'Kategori 2 - Opgavekøb';
    default:
      return 'Unknown solution type';
  }
}

export default {
  offerLabels(offer: Offer0214) {
    const { tenderData } = offer;
    return [awardTypeLabel(tenderData.awardCriteriaType)];
  },
  offerDetails(_offer: Offer) {
    return '';
  },
  offerLongDescription(offer: Offer) {
    const { tenderData } = offer as Offer0214;
    return `${tenderData.description}`;
  },
  tenderLongDescription(tender: Tender) {
    return tender.description;
  },
  tenderValues(tender: Tender0214): { label: string, name: string, value: string }[] {
    return [
      { label: 'Tildelingskriterie', name: 'awardType', value: awardTypeLabel(tender.awardCriteriaType) },
    ];
  },
  offerValues(offer: Offer0214): { label: string, name: string, value: string }[] {
    const { tenderData } = offer;
    return [
      { label: 'Tildelingskriterie', name: 'awardType', value: awardTypeLabel(tenderData.awardCriteriaType) },
    ];
  },
  getColumnValues(tender: Tender0214) {
    return [solutionTypeLabel(tender.data.solutionType),
      getValueString(tender.data.solutionType, tender.data.estimatedValue),
      tender.data.serviceAreas ?? ''];
  },
  getTableHeaders() {
    return [{
      title: 'Kategori',
      align: 'start' as const,
      value: 'categoryChoice',
      sortRaw: (a: Tender0214, b: Tender0214) => solutionTypeLabel(a.data.solutionType)
        .localeCompare(solutionTypeLabel(b.data.solutionType)),
      sortable: true,
    },
    {
      title: 'Værdi',
      align: 'start' as const,
      value: 'estimatedValue',
      sortRaw: (a: Tender0214, b: Tender0214) => {
        console.log(a.data.estimatedValue, b.data.estimatedValue);
        return (a.data.estimatedValue ?? 0) < (b.data.estimatedValue ?? 0) ? -1 : 1;
      },
      sortable: true,
    },
    {
      title: 'Ydelsesområder',
      align: 'start' as const,
      value: 'serviceAreas',
      sortable: false,
    },
    ];
  },
  searchOffersText(search: string, offers: Offer0214[]): Offer[] {
    const searchString = search.toLowerCase();
    return offers.filter(
      (o) => o.sender.companyName
        .concat(o.tenderData.description ?? '') // TODO: Concatenate all text fields?
        .toLowerCase()
        .includes(searchString),
    );
  },
};
